import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTheme } from 'styled-components'

import { HeaderStyled, MobileMenuStyled } from '../styles/HeaderStyled'
import { TitleS, BodyS } from '../styles/Typography'

import ButikLogoSmall from './icons/ButikLogoSmall'

/**
 * The main header.
 * @param {ComponentProps} props
 * @param {String} props.textColor
 * @param {String} props.backgroundColor
 * @param {String} props.logoColor
 * @param {String} props.logoReverseColor
 * @param {Boolean} props.hideLogo
 * @param {Boolean} props.isAnimatedCover
 * @param {Boolean} props.isZanzibar
 * @returns {StatelessComponent}
 */
const Header = ({
  textColor,
  backgroundColor,
  logoColor,
  logoReverseColor,
  hideLogo,
  isAnimatedCover,
  isZanzibar,
}) => {
  const theme = useTheme()

  const [shouldRender, setShouldRender] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    setShouldRender(true)
  }, [])

  return shouldRender ? (
    <>
      <HeaderStyled textColor={textColor} isAnimatedCover={isAnimatedCover}>
        <div className="header-group top">
          {!hideLogo && (
            <Link to="/" className="logo-link">
              <ButikLogoSmall
                className="logo"
                color={logoColor ? logoColor : theme.logoBackground}
              />
            </Link>
          )}
          {!isZanzibar && (
            <div className="menu-btn">
              <a>
                <BodyS
                  className="menu-title"
                  color={textColor}
                  weight={700}
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                >
                  <span className={isMenuOpen ? 'btn-close show' : 'btn-close'}>
                    [ Close ]
                  </span>
                  <span className={isMenuOpen ? 'btn-menu' : 'btn-menu show'}>
                    [ Menu ]
                  </span>
                </BodyS>
              </a>
            </div>
          )}
        </div>
      </HeaderStyled>

      <MobileMenuStyled
        backgroundColor={backgroundColor}
        className={isMenuOpen ? 'active' : ''}
      >
        <div className="header-group">
          {!hideLogo && (
            <Link to="/" className="logo-link">
              <ButikLogoSmall
                className="logo"
                color={
                  isZanzibar
                    ? theme.backgroundPrimary
                    : logoReverseColor
                    ? logoReverseColor
                    : theme.logoBackground
                }
              />
            </Link>
          )}
          <div className="menu-btn">
            <a>
              <BodyS
                className="menu-title"
                color={theme.textHighlight}
                weight={700}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span className={isMenuOpen ? 'btn-close show' : 'btn-close'}>
                  [ Close ]
                </span>
                <span className={isMenuOpen ? 'btn-menu' : 'btn-menu show'}>
                  [ Menu ]
                </span>
              </BodyS>
            </a>
          </div>
        </div>
        <div className={isMenuOpen ? 'menu-items open' : 'menu-items close'}>
          <a
            href="https://shop.paylogic.com/fdd8ca2b80cb46eb947e0ed302fe39b0"
            target="_blank"
          >
            <TitleS color={theme.textHighlight} className="menu-item item-1">
              Webshop
            </TitleS>
          </a>
          <Link to="/lineup" onClick={() => setIsMenuOpen(false)}>
            <TitleS color={theme.textHighlight} className="menu-item item-2">
              Lineup
            </TitleS>
          </Link>
          <Link to="/festival-a-z" onClick={() => setIsMenuOpen(false)}>
            <TitleS color={theme.textHighlight} className="menu-item item-3">
              Festival A-Z
            </TitleS>
          </Link>
          <Link to="/awareness" onClick={() => setIsMenuOpen(false)}>
            <TitleS color={theme.textHighlight} className="menu-item item-4">
              Awareness
            </TitleS>
          </Link>
          <Link to="/sustainability" onClick={() => setIsMenuOpen(false)}>
            <TitleS color={theme.textHighlight} className="menu-item item-5">
              Sustainability
            </TitleS>
          </Link>
          <Link to="/zanzibar" onClick={() => setIsMenuOpen(false)}>
            <TitleS color={theme.textHighlight} className="menu-item item-6">
              Zanzibar
            </TitleS>
          </Link>
        </div>
      </MobileMenuStyled>
    </>
  ) : (
    <></>
  )
}

export default Header
