export const theme = {
  backgroundPrimary: '#fff',
  backgroundDark: '#343434',
  backgroundHighlight: '#ff7d23',
  textPrimary: '#343434',
  textLight: '#fff',
}

const colors = {
  white: '#fff',
  black: '#343434',
  grey: '#292929',
  orange: '#ff7d23',
  purple: '#7355d7',
}

export const blackTheme = {
  // background
  backgroundPrimary: colors.white,
  backgroundDark: colors.purple,
  backgroundHighlight: colors.orange,
  // text
  textPrimary: colors.black,
  textLight: colors.white,
  textHighlight: colors.purple,
  textReverse: colors.orange,
  // cursor
  cursorBackground: colors.orange,
  cursorVideoBackground: colors.orange,
  // logo
  logoBackground: colors.purple,
  // cookies
  cookiesBannerBackground: colors.grey,
}
