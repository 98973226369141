import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

  * {
    box-sizing: border-box;
    word-wrap: break-word;
  }

  html {
    min-height: 100%;
  }

  html,
  body {
    font-style: normal;
    font-stretch: normal;
    font-family: 'Space Grotesk', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
  }

  .no-scroll {
    overflow: hidden !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ul,
  menu,
  dir {
    padding-left: 0;
  }

  a,
  button {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  figure {
    margin: 0;
  }

  *:focus,
  *:active {
    outline: 0 !important;
  }

  ::-moz-selection { 
    color: #fff;
    background: #343434;
  }

  ::selection {
    color: #fff;
    background: #343434;
  }

  img {
    max-width: 100%;
  }
`

export default GlobalStyles
